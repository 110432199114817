<template>
  <div id="freelancerDiv" style=" padding-top: 60px; background: #f5f6f8; height: 100vh">
    <div class="hiddendiv"></div>




      <div style=" font-size: 16px; margin: 0 auto;">




          <div class="page-header" style=" width: 100%; text-align: center; margin-left:0;margin-top:0px;margin-bottom: 10px; " >Neye ihtiyacın var?</div>
          <div class="hiddendiv"></div>
          <div style="text-align: center">
            <router-link to="/become_a_freelancer">
              <button class="super-button-light" style="padding: 24px!important; height: 80px; font-size: 18px;">Freelancer  olmak istiyorum.</button>
            </router-link>
            <div class="hiddendiv10"></div>
            <router-link to="/become_a_startup">
              <button style="margin-left: 20px; margin-right: 20px; padding: 24px!important; min-height: 100px; font-size: 18px;" class="super-button-light">Projem için bir freelancer'a ihtiyacım var</button>
            </router-link>
          </div>

          <div class="hiddendiv"></div> <div class="hiddendiv"></div>


      </div>




  </div>
</template>

<script>

  export default {
    name: "src-pages-body-onboarding-main-v1",
    components: {

    },

    data() {
      return {

      }
    },

    methods: {

    },

    computed: {

    }
  }
</script>

<style scoped>

   .super-button-light:hover{
     background-color: #2d3640!important;
     color: white!important;
   }
</style>
